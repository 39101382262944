import { $http } from "@/plugins/api.js";

const state = {
  senders: [],
  selectedSender: null,
  serverTotal: 0,
  displayName: [],
};

const getters = {
  senders: (state) => (state.senders ? state.senders : []),
  selectedSender: (state) => (state.selectedSender ? state.selectedSender : {}),
  serverTotal: (state) => state.serverTotal,
  displayName: (state) => state.displayName,
};

const actions = {
  async fetchAntiSpoofDisplayNames(
    { commit },
    {
      tier_id,
      tier,
      results = 10,
      page = 1,
      sortBy = "id",
      sortDir = "asc",
      filter = null,
      type = null,
    }
  ) {
    let requestURL = `/restapi/${tier}/${tier_id}/antispoof/display_names`;
    if (results) requestURL += `?results=${results}`;
    if (page) requestURL += `&page=${page}`;
    if (sortBy) requestURL += `&sortkey=${sortBy}`;
    if (sortDir) requestURL += `&sortdir=${sortDir}`;
    if (filter) requestURL += `&filter=${filter}`;
    if (type) requestURL += `&type=${type}`;

    const response = await $http.get(requestURL);

    commit("setDisplayName", response.data.data);
    commit("setServerTotal", response.data.total);
  },
  async addAntiSpoofDisplayName(_context, { tier, tier_id, name, email }) {
    const res = await $http.post(
      `/restapi/${tier}/${tier_id}/antispoof/display_names`,
      {
        display_name: name,
        email,
      }
    );
    return res;
  },
  async fetchSenders(
    { commit },
    {
      domainId,
      results = 10,
      page = 1,
      sortBy = "id",
      sortDir = "asc",
      filter = null,
      type = null,
    }
  ) {
    let requestURL = `/restapi/domains/${domainId}/antispoof/senders`;
    if (results) requestURL += `?results=${results}`;
    if (page) requestURL += `&page=${page}`;
    if (sortBy) requestURL += `&sortkey=${sortBy}`;
    if (sortDir) requestURL += `&sortdir=${sortDir}`;
    if (filter) requestURL += `&filter=${filter}`;
    if (type) requestURL += `&type=${type}`;

    const response = await $http.get(requestURL);
    commit("setSenders", response.data);
    commit("setServerTotal", response.data.total);
  },

  async fetchSender({ commit }, { domainId, id }) {
    const response = await $http.get(
      `/restapi/domains/${domainId}/antispoof/senders/${id}`
    );
    commit("setSelectedSender", response.data);
    commit("setServerTotal", response.data.total);
  },

  async updateSender({ commit }, { domainId, id, sender }) {
    const response = await $http.put(
      `/restapi/domains/${domainId}/antispoof/senders/${id}`,
      {
        domain: domainId,
        id: id,
        ip: sender.ip,
        hostname: sender.hostname,
        comment: sender.comment,
        netmask: sender.netmask,
      }
    );
    commit("setSelectedSender", response.data);
  },
  async batchCreateSenders({ commit }, { domainId, senders }) {
    const entries = [];
    senders.forEach((sender, index) => {
      const key = `${index}`;
      const value = {
        method: "post",
        path: `domains/${domainId}/antispoof/senders`,
        parameters: {
          ip: sender.ip,
          netmask: sender.netmask,
          comment: sender.comment,
          hostname: sender.hostname,
        },
      };

      entries.push([key, value]);
    });
    const res = await $http.post("/restapi/batch", Object.fromEntries(entries));
    commit("setSelectedSender", res);
    return res;
  },

  async createSender({ commit }, { domainId, formData }) {
    const response = await $http.post(
      `/restapi/domains/${domainId}/antispoof/senders`,
      formData
    );
    commit("addSender", response.data);
    return response.data;
  },

  async batchDeleteSenders({ commit }, { domainId, senders }) {
    const entries = [];

    for (const sender of senders) {
      const key = `${sender.id}`;
      const value = {
        method: "delete",
        path: `domains/${domainId}/antispoof/senders/${sender.id}`,
      };

      entries.push([key, value]);
    }

    const res = await $http.post("/restapi/batch", Object.fromEntries(entries));
    const ids = Object.keys(res.data).map((item) => item);
    commit("deleteSenders", ids);
  },
  async createSettings(
    _context,
    { enabled, use_display_names, use_spf, domainId }
  ) {
    const res = await $http.post(
      `/restapi/domains/${domainId}/antispoof/settings`,
      {
        enabled,
        use_spf,
        use_display_names,
      }
    );
    return res;
  },
  async updateSettings(
    _context,
    { enabled, use_display_names, use_spf, domainId }
  ) {
    const res = await $http.put(
      `/restapi/domains/${domainId}/antispoof/settings`,
      {
        enabled,
        use_spf,
        use_display_names,
      }
    );
    return res;
  },
  clearList({ commit }) {
    commit("setSenders", []);
  },

  async batchCreateDisplayName({ commit }, { tier_id, display_name }) {
    const entries = [];
    display_name.forEach((displayName, index) => {
      const key = `${index}`;
      const value = {
        method: "post",
        path: `domains/${tier_id}/antispoof/display_names`,
        parameters: {
          display_name: displayName.displayName,
          email: displayName.email,
        },
      };

      entries.push([key, value]);
    });
    const res = await $http.post("/restapi/batch", Object.fromEntries(entries));
    commit("setDisplayName", res);
    return res;
  },

  async importDisplayNamesFromFile({ comment }, { tier, tier_id, list_file }) {
    const formData = new FormData();
    formData.append("list_file", list_file);

    const server_resp = await $http.post(
      `/restapi/${tier}/${tier_id}/antispoof/display_names/import`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return server_resp.data;
  },

  async batchUpdateDisplayName({ commit }, { tier, tier_id, display_names }) {
    const entries = [];
    display_names.forEach((displayName, index) => {
      const key = `${index}`;
      const value = {
        method: "put",
        path: `domains/${tier_id}/antispoof/display_names/${displayName.id}`,
        parameters: {
          display_name: displayName.display_name,
          email: displayName.email,
          enabled: displayName.enabled,
        },
      };

      entries.push([key, value]);
    });
    const res = await $http.post("/restapi/batch", Object.fromEntries(entries));
    return res;
  },

  async batchDeleteDisplayNames({ commit }, { tier, tier_id, items }) {
    const entries = [];

    for (const item of items) {
      const key = `${item.id}`;
      const value = {
        method: "delete",
        path: `${tier}/${tier_id}/antispoof/display_names/${item.id}`,
      };

      entries.push([key, value]);
    }

    const res = await $http.post("/restapi/batch", Object.fromEntries(entries));
    const ids = Object.keys(res.data).map((item) => item);
    commit("deleteDisplayNames", ids);
  },
};

const mutations = {
  setSenders: (state, data) => (state.senders = data.data),
  setSelectedSender: (state, data) => (state.selectedSender = data),
  addSender: (state, data) => {
    state.senders.push(data);
    state.serverTotal = state.serverTotal + 1;
  },
  updateSender: (state, data) => (state.senders = data),
  deleteSenders(state, ids) {
    for (const id of ids) {
      state.senders = state.senders.filter(
        (sender) => Number(sender.id) !== Number(id)
      );
      state.serverTotal = state.serverTotal - 1;
    }
  },
  setServerTotal: (state, payload) => (state.serverTotal = payload),
  setDisplayName: (state, payload) => (state.displayName = payload),
  deleteDisplayNames(state, ids) {
    for (const id of ids) {
      state.displayName = state.displayName.filter(
        (displayName) => Number(displayName.id) !== Number(id)
      );
      state.serverTotal = state.serverTotal - 1;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
