export const useSort = (currentSortedBy, currentSortedDir) => {
  const applySorting = (sortArray) => {
    const result = sortArray.sort((a, b) => {
      const aValue = a[currentSortedBy];
      const bValue = b[currentSortedBy];

      const aValueFormatted =
        typeof aValue === "string" ? aValue.toLowerCase() : aValue;
      const bValueFormatted =
        typeof bValue === "string" ? bValue.toLowerCase() : bValue;

      if (currentSortedDir === "desc") {
        return bValueFormatted > aValueFormatted
          ? 1
          : aValueFormatted > bValueFormatted
            ? -1
            : 0;
      } else {
        return aValueFormatted > bValueFormatted
          ? 1
          : bValueFormatted > aValueFormatted
            ? -1
            : 0;
      }
    });
    return result;
  };

  return {
    applySorting,
  };
};

export default { useSort };
