import axios from "axios";
import { store } from "../store/index";
import { i18n } from "../i18n.js";
import { useProgress } from "@marcoschulte/vue3-progress";

/**
 * Displays toast or system alert depending on which error code is returned
 * @param {error} err Error response
 */
const handleHttpError = (err) => {
  let message;
  let type;
  let duration = 5000;
  let alertType = "system";

  // Link Lock policy doesn't exists for msp tier
  if (err.message !== "User not found.") {
    if (err.message.includes("401") && location.pathname !== "/auth/sign-in") {
      alertType = "none";
      localStorage.clear();
      window.location.assign("/");
    } else if (err.response?.status === 400) {
      alertType = "toast";
    } else if (err.response?.status === 403) {
      alertType = "toast";
      message = i18n.global.t("Client error: Forbidden.");
    } else if (err.response?.status === 404) {
      // Note. This is a temporary toast skip according to the ticket titanhq/dev/st/pui-spamtitan#366
      // Once inherited value is provided for link-lock please remove the below `if` statement
      if (
        err.config?.url.includes("link-lock") &&
        err.config?.method === "get"
      ) {
        alertType = "skip";
      } else {
        alertType = "toast";
      }
      window.location.assign("/");
    } else if (err.response?.status === 405) {
      message = i18n.global.t("Client error: Method not allowed.");
    } else if (err.response?.status === 408) {
      message = i18n.global.t("Client error: Request timed out.");
    } else if (err.response?.status === 422) {
      alertType = "toast";
      duration = 50000;
    } else if (err.response?.status === 429) {
      message = i18n.global.t("Client error: Too many requests.");
    } else if (String(err.response?.status).startsWith(50)) {
      alertType = "toast";
    } else if (err.toJSON().message === "Network Error") {
      message = i18n.global.t(
        "Connection lost. Please check your network and try again."
      );
      type = "no-connection";
    }

    if (alertType === "system")
      store.dispatch(
        "alert/displayAlert",
        {
          position: "top",
          variant: "danger",
          message,
          duration,
          type,
        },
        { root: true }
      );

    if (
      alertType === "toast" &&
      err.response !== undefined &&
      !err.response.data.error.includes("2FA is enabled") &&
      !err.response.data.error.includes("Incorrect code entered") &&
      !err.response.data.error.includes("Code required") &&
      !err.response.data.error.includes("Invalid code")
    )
      store.dispatch(
        "toast/displayToast",
        {
          title: i18n.global.t("Error"),
          message: err.response.data.error,
          variant: "danger",
          duration,
        },
        { root: true }
      );
  }
};

axios.defaults.withCredentials = true;
export const $http = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
});

export default {
  install(app) {
    app.config.globalProperties.$http = $http;

    /**
     * Attaches JWT as Bearer token in Authorization header to all subsequent requests.
     * @param {JWT} token JWT received from server
     */
    app.config.globalProperties.$http.setAuthorisationToken = function (token) {
      if (token) {
        this.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      } else {
        delete axios.defaults.headers.common["Authorization"];
      }
    };
    /**
     * Intercepts HTTP responses and handles errors
     */
    const progresses = [];

    app.config.globalProperties.$http.interceptors.request.use(
      (config) => {
        progresses.push(useProgress().start());
        return config;
      },
      (err) => {
        progresses.pop()?.finish();
        handleHttpError(err);
        return Promise.reject(err);
      }
    );
    app.config.globalProperties.$http.interceptors.response.use(
      (config) => {
        progresses.pop()?.finish();
        store.getters["alert/alert"]?.type === "no-connection" &&
          store.dispatch("alert/closeAlert");
        return config;
      },
      (err) => {
        handleHttpError(err);
        progresses.pop()?.finish();
        return Promise.reject(err);
      }
    );
  },
};
