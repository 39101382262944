<template>
  <div class="tw-flex tw-h-screen tw-overflow-hidden">
    <t-side-nav :navItems="navItems" />
    <div
      class="tw-ml-14 tw-flex tw-flex-1 tw-flex-col tw-overflow-y-auto tw-bg-light lg:tw-ml-0"
    >
      <main>
        <div class="container-fluid">
          <t-page-header :user="user" :user-links="userLinks" />
          <router-view />
        </div>
      </main>
      <t-footer :version="appVersion" :logo="logo" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import TSideNav from "@/components/TSideNav";
import TPageHeader from "@/components/TPageHeader";
import {
  SPAM_CONFIG,
  WEB_CONFIG,
  SAFE_CONFIG,
  ARC_CONFIG,
  PRODUCTS,
} from "@/constants";

import { version } from "../../../package";

export default {
  components: {
    TSideNav,
    TPageHeader,
  },
  name: "ProfileLayout",
  data() {
    return {
      navItems: this.$getPrimaryNavItems("profile"),
      appVersion: version,
    };
  },
  computed: {
    ...mapGetters("authentication", ["authUser", "selectedAccount"]),
    ...mapGetters("userInterface", ["uiLogo"]),
    ...mapGetters("support", ["userLinks"]),
    user() {
      const email = this.authUser?.email ?? null;
      const accountname = this.authUser?.accountname ?? null;

      return {
        email,
        accountname,
      };
    },
    logo() {
      return this.uiLogo;
    },
  },
  methods: {
    ...mapActions("support", ["setUserLinks"]),
  },
  created() {
    if (this.selectedAccount?.account_type_name === "system") {
      const userLinksValue = [
        {
          link: "/system",
          icon: "columns-gap",
          title: this.$t("System/Overview"),
        },
      ];
      this.setUserLinks(userLinksValue);
    }
  },
};
</script>
